
.the-talks {
    @media (min-width:768px) {
        display:flex;
        gap: clamp(1em, 3vw, 2em);
        margin-bottom:clamp(1em, 3vw, 2em);
    }

    .talk-item {
        width:auto;
        min-width: 48%;
        //margin-bottom:0;

        .talk-item__description {
            padding: clamp(1em,2.4vw,2.2em);
            padding-bottom:0;
        }
    }
}