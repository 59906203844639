#main nav.talks-years {
    position: relative;
    padding: 0;
    overflow-x: auto;
    margin-bottom:2em;

    ul {
        list-style:none;
        margin:0;
        padding:clamp(1em, 2vw, 2em) 0;
        display:flex;
        gap:clamp(1em, 2vw, 2em);

        li {
            a {
                color:var(--font-color);
                padding:0;
                font-size: var(--step-2);
                font-weight:700;
                text-decoration: none;
                background: none;
                transition: .2s all;

                &:hover {
                    color:var(--accent-color);
                }
            }
            &.active {
                a {
                    border-bottom:.25em solid var(--accent-color);
                    font-weight:700;
                }
            }
        }
    }    
}