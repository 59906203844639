html, body {
    font-family: var(--default-font);
    font-weight: 500;
    line-height:1.3;
    font-size:16px;
}


.introduction p {
    font-size: clamp(1.1em,2.4vw,2.1rem);
    //font-size:var(--step-3);
    font-weight:600;
    margin-bottom:var(--large-bottom-margin);
}

.date {
    
    
    &__headline {
        //font-size:clamp(1em,3vw, 2.4em);
        font-size:var(--step-3);
        margin:0;
        padding:0;

    }
    &__date {
        display:flex;
        align-items: center;
        font-size:clamp(1em,3vw, 2.4em);

        h4 {
            //font-size:2.2em;
            font-size:var(--step-7);
            margin:0 .3em 0 0;
            padding:0;
        }
    }
}

.location p,
.address p {
    //font-size:clamp(1rem,2vw,1.3rem)
    font-size:var(--step-0);
}
.location {
    margin-bottom:var(--large-bottom-margin);
}
.address {
    display:flex;
    &__icon {
        svg {
            height: 3em;
            width: 2.5em;
            padding: 0.3em 0;
            margin-right: 1em;
        }
    }
    address, address p {
        font-style: normal;
        margin:0;
        padding:0;
    }
}


h1 {
    //font-size:clamp(2em,4vw,4em);
    font-size:var(--step-6);
}
h2 {
    //font-size:clamp(1.4em,4vw,1.8em);
    font-size:var(--step-2);
}
p + h2 {
    margin-top:3em;
}

h3 {
    //font-size:clamp(1.2em,4vw,1.6em);
    font-size:var(--step-2);
    padding-top:0;
    margin-top:0;   
}
p + h3 {
    margin-top:3em;
}
p {
    //font-size:clamp(1em, 3vw, 1.3em);
    font-size: var(--step-0);
    max-width:84ch;
}

#main a,
.introduction a  {
    text-decoration:none;
    color:var(--font-color);
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 50%, var(--underline-green) 50%, var(--underline-green) 100%);
}
#main a.meetup-link,
a.meetup-link,
.btn {
    font-size: 1em;
    display: inline-flex;
    align-items: center;
    padding: 0.1em 0.3em 0 .8em;
    background: var(--button-background);
    border: .2em solid var(--button-border);
    color:var(--font-color);
    transition: color .2s;
    border-radius: 300px;
    font-weight: 600;
    text-decoration: none;
    margin-top:-0.2em;

    &:hover {
        color: var(--accent-color);
    }
    svg {
        height:2.3em;
        width:2.3em;
    }
}
#main a.meetup-link {
    font-size:.7em;
}
.btn--circle {
    height: 3em;
    width: 2em;
    padding: 0.1em 0.6em;
    margin-right:1em;
}
.vertically-centered {
    display: flex;
    align-items: center;
}

#main a.social-link {
    display:inline-flex;
    align-items: center;
    background:transparent;
    color:var(--social-link-green);
    &:hover {
        color:var(--accent-color);
    }
    svg {
        height:1.9em;
        width:1.9em;
        margin-right:0.2em;
    }

}