:root {
  --font-color:         #000;

  --button-background:  #A0F1CA;
  --button-border:      #A0D9BD;
  --underline-green:    #65D6B1;
  --social-link-green:  #35846A;

  --accent-color:       #FF4FD3;

  --default-font:       'Poppins';

  --large-bottom-margin: clamp(5rem, 6vw, 9rem);
}


/* @link https://utopia.fyi/type/calculator?c=320,16,1.125,1920,21,1.2,7,2,1920&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l&g=s,l,xl,12 */

:root {
  --step--2: clamp(0.79rem, 0.77rem + 0.12vw, 0.91rem);
  --step--1: clamp(0.89rem, 0.85rem + 0.21vw, 1.09rem);
  --step-0: clamp(1.00rem, 0.94rem + 0.31vw, 1.31rem);
  --step-1: clamp(1.13rem, 1.04rem + 0.45vw, 1.58rem);
  --step-2: clamp(1.27rem, 1.14rem + 0.62vw, 1.89rem);
  --step-3: clamp(1.42rem, 1.25rem + 0.84vw, 2.27rem);
  --step-4: clamp(1.60rem, 1.38rem + 1.12vw, 2.72rem);
  --step-5: clamp(1.80rem, 1.51rem + 1.46vw, 3.27rem);
  --step-6: clamp(2.03rem, 1.65rem + 1.89vw, 3.92rem);
  --step-7: clamp(2.28rem, 1.80rem + 2.42vw, 4.70rem);
}