
header nav {
    display:flex;
    justify-content: flex-end;

  
    ul {
        margin: 0;
        padding: 0 0 10px;
        display: flex;
        list-style:none;
        align-items: center;
        width: 100%;
        justify-content: space-between;

        @media (min-width:480px) {
            justify-content: end;
            gap:1em;
            min-height:6em;
        }

        li {
            a {
                text-decoration: none;
                color: #1a181c;
                text-align: center;
                font: bold 1em/1 var(--default-font);
                margin: 0;
                padding: 8px 4px 2px;
                border-bottom: 4px solid transparent;
                transition: .2s all;

                @media (min-width:768px) {
                    font-size:1.2em;
                }

                &:hover {
                    color: var(--accent-color);
                }
            }
            &.active a {
                //color: #97bf0e;
                position: relative;
                border-color: var(--accent-color);
            }
        }
    }
}