// Le Header

.le-grid header {
    padding-top:1em;
    margin-bottom:3em;
}
@media (min-width:768px){
    .le-grid header {
        grid-column: 1/15;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        padding: 1em 2vw;
    }
    .start .le-grid header {
        grid-column: 2/15;
        display: block;
        padding:1em 2vw 1em 0;
    }
}

.logo {
    display:flex;
    align-items: center;
    max-width:100%;
    margin:1em 0 1em;

    .start & {
        flex-direction: column;
        align-items: flex-start;
        max-width: clamp(5em,64vw,70em);
    }
    .signet {
        width:3.2em;
        height: auto;
        margin-right:.5em;

        .start & {
            margin-bottom:3%;
            height:auto;
            width:20%;
        }
    }
    .logotype {
        height:2.8em;
        width: auto;;

        .start & {
            height:auto;
            width:100%;
        }
    }
    @media (min-width:768px) {
        .signet {
            width:4.6em;
            margin-right:1em;
        }
        .logotype {
            height:3.6em;
        } 
    }
}
