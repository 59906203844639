footer {
    text-align:center;
    padding-top:3em;

    a {
        color:var(--font-color);
    }
    ul {
        list-style: none;
        display:flex;
        justify-content: center;
        margin:0;
        padding:0;
        gap:1em;
        
        li {
            margin:0;
            padding:0;

            a {
                font-weight:600;
                text-decoration:none;
                transition: .2s all;

                &:hover {
                    color: var(--accent-color);
                }
            }
        }
    }
    nav.supportNavi {
        padding-bottom:2em;
    }
    nav.socialMedia {
        padding:0;
        ul {
            margin-bottom:1em;
            gap: 0;

            li {
                a {
                    transition: .2s all;
                    svg.soc-icons {
                        height:3em;
                        width:3em;

                        @media (min-width:768px) {
                            width:4em;
                            height:4em;
                        }
                    }
                }
            }
        }
    }
    
    .colophon {

    }
    .cookies {

    }
    .sponsor,
    .colophon,
    .cookies {
        padding-bottom:1em;
        p {
            font:500 .9rem/1.4 var(--default-font);
            margin:0;
            padding:0;
        }
    }
    .sponsor {        
        padding-bottom:3em;
        display: flex;
        justify-content: center;
        padding-bottom: 2em;
        flex-direction: column;
        align-items: center;
        img {
            width:clamp(254px, 30vw, 320px);
            max-width: 100%;
            height:auto;
        }
        p {
        }
    }
    @media (min-width:768px) {
        display:grid;
        grid-template-columns:70% 30%;

        nav.supportNavi {
            grid-column: 2;
            ul {
                justify-content: end;
                margin-top:1.2em;
            }
        }
        nav.socialMedia {
            ul {
                justify-content: start;
                padding:0;
                margin:0 0 3em 0;
            }
        }
        
        .colophon {
            grid-column: 1;
            grid-row: 2;
            text-align: left;
        }
        .cookies {
            grid-column: 1;
            grid-row: 3;
            text-align: left;
        }
        .sponsor {
            grid-column: 2;
            grid-row: 2 / 4;
            align-items: end;
        }
        
    }
}